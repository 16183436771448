<template>
    <div
        class="home"
        v-loading="loading"
    >
        <div class="white-bg">
            <div class="baccount-tit">
                <h3 class="g-title">验证邮箱</h3>
            </div>
            <div class="zpanle modifyp-panle">
                <div class="account-hd">
                    <div class="account-cnt">
                        <img
                            class="top-icon-img"
                            :src="$img.email"
                        />
                        <p class="accont-slogin">确认绑定邮箱</p>
                        <p style="margin-top: 10px;">验证码已发送至{{ accountForm.email }}</p>
                        <p>请登录您的邮箱获取六位验证码，输入到下面输入框确认邮箱绑定操作。</p>
                    </div>
                </div>

                <div class="panner-bd modifypd">
                    <el-form
                        ref="accountForm"
                        :rules="rules"
                        :model="accountForm"
                        class="g-form"
                        style="width:370px;"
                    >
                        <el-form-item prop="zma">
                            <el-input
                                v-model="accountForm.zma"
                                placeholder="请输入邮箱验证码"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>

                        <el-form-item class="btn-wrap ml-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="handleSubmit"
                                >确认修改</el-button>
                            </div>
                        </el-form-item>

                        <el-form-item class="btn-wrap back-wrap mr-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="$router.back(-1)"
                                >返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "validEmail",
    metaInfo() {
        return {
            title: "验证邮箱 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            accountForm: {
                email: "", //邮箱地址
                zma: "", //验证码
                action: 3, //获取邮箱验证码 - 其他
                user_bindEmail: 1 //获取邮箱验证码 - 邮箱绑定，而不是发送短信
            },
            rules: {
                zma: [
                    {
                        required: true,
                        message: "请输入邮箱验证码",
                        trigger: ["change", "blur"]
                    },
                    {
                        min: 6,
                        max: 6,
                        message: "请输入6位有效邮箱验证码",
                        trigger: ["change", "blur"]
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]),//刷新用户数据方法
        //确认绑定邮箱
        handleSubmit() {
            let _this = this;
            _this.$refs["accountForm"].validate((valid, validInfoObj) => {
                if (valid) {
                    _this.post("/user/user/bind_email", { email: _this.accountForm.email, code: _this.accountForm.zma }, data => {
                        if (data.code != 200) {
                            _this.$message.error(data.msg);
                            return;
                        }
                        _this.$message.success("邮箱绑定成功");
                        _this.ajaxUserInfo();
                        _this.$router.push("/user/setting/");
                    });
                }
            });
        }
    },
    created() {
        this.accountForm.email = this.$route.query.email;
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


